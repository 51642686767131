import axios from "axios";

const url = "https://covid19.mathdro.id/api";
// const url2 = "https://disease.sh/v3/covid-19/all";

// export const fetchData = async country => {
//   let changeableUrl = url;
//   if (country) {
//     changeableUrl = `${url}/countries/${country}`;
//   }
//   try {
//     const {
//       data: { confirmed, recovered, deaths, lastUpdate }
//     } = await axios.get(changeableUrl);

//     return {
//       confirmed,
//       recovered,
//       deaths,
//       lastUpdate
//     };
//   } catch (error) {
//     console.log(error);
//   }
// };

// export const fetchData2 = async country => {
//   let changeableUrl = url2;
//   if (country) {
//     changeableUrl = `${url2}/countries/${country}`;
//   }
//   try {
//     const {
//       data: {
//         cases,
//         recovered,
//         deaths,
//         todayCases,
//         todayRecovered,
//         todayDeaths,
//         updated
//       }
//     } = await axios.get(changeableUrl);

//     return {
//       cases,
//       recovered,
//       deaths,
//       todayCases,
//       todayRecovered,
//       todayDeaths,
//       updated
//     };
//   } catch (error) {
//     console.log(error);
//   }
// };

export const fetchDailyData = async () => {
  try {
    const { data } = await axios.get(`${url}/daily`);
    const modifiedData = data.map(dailyData => ({
      confirmed: dailyData.confirmed.total,
      deaths: dailyData.deaths.total,
      date: dailyData.reportDate
    }));
    return modifiedData;
  } catch (error) {
    console.log(error);
  }
};

export const fetchCountries = async () => {
  try {
    const {
      data: { countries }
    } = await axios.get(`${url}/countries`);
    return countries.map(country => country.name);
  } catch (error) {
    console.log(error);
  }
};

// export const getCountriesData = async () => {
//   fetch("https://disease.sh/v3/covid-19/countries")
//     .then(response => response.json())
//     .then(data => {
//       const countries = data.map(country => ({
//         name: country.country,
//         value: country.countryInfo.iso2
//       }));
//       let sortedData = sortData(data);
//       // setCountries(countries);
//       // setMapCountries(data);
//       // setTableData(sortedData);
//       return countries;
//     });
// };
