import React from "react";
import { Card, CardContent, Typography, Grid } from "@material-ui/core";
import CountUp from "react-countup";
import CircularProgress from "@material-ui/core/CircularProgress";
// import cx from "classnames";
import styles from "./Cards.module.css";

const Cards = ({
  data: {
    cases,
    recovered,
    deaths,
    todayCases,
    todayRecovered,
    todayDeaths,
    updated
  },
  setCasesType,
  casesType
}) => {
  if (!cases) return <CircularProgress size={100} />;
  return (
    <div className={styles.container}>
      <Grid container spacing={3} justify="center">
        <Grid
          item
          component={Card}
          xs={12}
          md={3}
          // className={cx(styles.card, styles.infected)}
          onClick={e => setCasesType("cases")}
          className={`${styles.cases} ${styles.card} 
            ${casesType === "cases" && styles.casesselected}`}
        >
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Cases
            </Typography>
            <Typography variant="h5">
              <CountUp start={0} end={cases} duration={2.5} separator="." />
            </Typography>
            <Typography color="textSecondary">
              {new Date(updated).toLocaleString([], {
                dateStyle: "medium"
              })}
            </Typography>
            <div style={{ display: "flex" }}>
              <Typography
                color="textSecondary"
                style={{ whiteSpace: "nowrap" }}
              >
                Today : &nbsp;
              </Typography>
              <Typography color="secondary">
                {todayCases
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
              </Typography>
            </div>
          </CardContent>
        </Grid>
        <Grid
          item
          component={Card}
          xs={12}
          md={3}
          // className={cx(styles.card, styles.recovered)}
          onClick={e => setCasesType("recovered")}
          className={`${styles.recovered} ${styles.card} 
            ${casesType === "recovered" && styles.recoveredselected}`}
        >
          <CardContent onClick={e => setCasesType("recovered")}>
            <Typography color="textSecondary" gutterBottom>
              Recovered
            </Typography>
            <Typography variant="h5">
              <CountUp start={0} end={recovered} duration={2.5} separator="." />
            </Typography>
            <Typography color="textSecondary">
              {new Date(updated).toLocaleString([], {
                dateStyle: "medium"
              })}
            </Typography>
            <div style={{ display: "flex" }}>
              <Typography
                color="textSecondary"
                style={{ whiteSpace: "nowrap" }}
              >
                Today : &nbsp;
              </Typography>
              <Typography color="secondary">
                {todayRecovered
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
              </Typography>
            </div>
          </CardContent>
        </Grid>
        <Grid
          onClick={e => setCasesType("deaths")}
          item
          component={Card}
          xs={12}
          md={3}
          // className={cx(styles.card, styles.deaths)}
          className={`${styles.deaths} ${styles.card} 
          ${casesType === "deaths" && styles.deathsselected}`}
        >
          <CardContent onClick={e => setCasesType("cases")}>
            <Typography color="textSecondary" gutterBottom>
              Deaths
            </Typography>
            <Typography variant="h5">
              <CountUp start={0} end={deaths} duration={2.5} separator="." />
            </Typography>
            <Typography color="textSecondary">
              {new Date(updated).toLocaleString([], {
                dateStyle: "medium"
              })}
            </Typography>
            <div style={{ display: "flex" }}>
              <Typography
                color="textSecondary"
                style={{ whiteSpace: "nowrap" }}
              >
                Today : &nbsp;
              </Typography>
              <Typography color="secondary">
                {todayDeaths
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
              </Typography>
            </div>
          </CardContent>
        </Grid>
      </Grid>
    </div>
  );
};

export default Cards;
