import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import numeral from "numeral";

const options = {
  legend: {
    display: false
  },
  elements: {
    point: {
      radius: 0
    }
  },
  maintainAspectRatio: false,
  tooltips: {
    mode: "index",
    intersect: false,
    callbacks: {
      label: function (tooltipItem, data) {
        return numeral(tooltipItem.value).format("+0,0");
      }
    }
  },
  scales: {
    xAxes: [
      {
        type: "time",
        time: {
          parser: "MM/DD/YY",
          tooltipFormat: "ll"
        }
      }
    ],
    yAxes: [
      {
        gridLines: {
          display: false
        },
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value, index, values) {
            return numeral(value).format("0a");
          }
        }
      }
    ]
  }
};

const buildChartData = (data, casesType) => {
  let chartData = [];
  if (data === undefined) return chartData;
  let lastDataPoint;
  for (let date in data.cases) {
    if (lastDataPoint) {
      let newDataPoint = {
        x: date,
        y: data[casesType][date] - lastDataPoint
      };
      chartData.push(newDataPoint);
    }
    lastDataPoint = data[casesType][date];
  }
  return chartData;
};

function LineGraph({ casesType, country }) {
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const url =
        country === "worldwide"
          ? "https://disease.sh/v3/covid-19/historical/all?lastdays=120"
          : `https://disease.sh/v3/covid-19/historical/${country}?lastdays=120`;
      await fetch(url)
        .then(response => {
          return response.json();
        })
        .then(data => {
          let chartData;
          if (country === "worldwide") {
            chartData = buildChartData(data, casesType);
          } else {
            chartData = buildChartData(data.timeline, casesType);
          }
          setData(chartData);
          // buildChart(chartData);
        });
    };

    fetchData();
  }, [casesType, country]);

  let bgColor, bColor;
  if (casesType === "cases") {
    bgColor = "rgba(127, 127, 255, 0.5)";
    bColor = "#7f7fff";
  } else if (casesType === "recovered") {
    bgColor = "rgba(127, 255, 127, 0.5)";
    bColor = "#7fff7f";
  } else {
    bgColor = "rgba(204, 16, 52, 0.5)";
    bColor = "#CC1034";
  }

  return (
    <div>
      {data?.length > 0 && (
        <Line
          data={{
            datasets: [
              {
                backgroundColor: bgColor,
                borderColor: bColor,
                data: data
              }
            ]
          }}
          options={options}
        />
      )}
    </div>
  );
}

export default LineGraph;
