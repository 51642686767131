import React from "react";
import "./Table.css";
import numeral from "numeral";

function Table({ countries, million, casesType }) {
  let typeMillion;
  if (million) {
    typeMillion = `${casesType}PerOneMillion`;
  }
  return (
    <div className="pane">
      <table className="table">
        <tbody>
          {countries.map((country, index) => (
            <tr key={index}>
              <td>{country.country}</td>
              <td>
                {million ? (
                  <strong>{numeral(country[typeMillion]).format("0,0")}</strong>
                ) : (
                  <strong>{numeral(country[casesType]).format("0,0")}</strong>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
