import React, { useState, useEffect } from "react";
import { Cards, Chart } from "./components";
import styles from "./App.module.css";
import coronaImage from "./images/new-image.png";
import Map from "./components/Map/Map";
import Table from "./components/Table/Table";
import {
  Card,
  CardContent,
  MenuItem,
  FormControl,
  Select
} from "@material-ui/core";
import LineGraph from "./components/LineGraph/LineGraph";
import "leaflet/dist/leaflet.css";

const App = () => {
  const [country, setInputCountry] = useState("worldwide");
  const [countryInfo, setCountryInfo] = useState({});
  // const [data, setData] = useState({});
  const [countries, setCountries] = useState([]);
  const [mapCountries, setMapCountries] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableDataMillion, setTableDataMillion] = useState([]);
  const [casesType, setCasesType] = useState("cases");
  const [mapCenter, setMapCenter] = useState({ lat: 34.80746, lng: -40.4796 });
  const [mapZoom, setMapZoom] = useState(2);

  const [showScroll, setShowScroll] = useState(false);
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };
  window.addEventListener("scroll", checkScrollTop);
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    fetch("https://disease.sh/v3/covid-19/all")
      .then(response => response.json())
      .then(data => {
        setCountryInfo(data);
      });
  }, []);

  useEffect(() => {
    const sortData = data => {
      let sortedData = [...data];
      console.log("Sorted", casesType);
      sortedData.sort((a, b) => {
        if (a[casesType] > b[casesType]) {
          return -1;
        } else {
          return 1;
        }
      });
      return sortedData;
    };

    const sortDataMillion = data => {
      let sortedData = [...data];
      let typeMillion = `${casesType}PerOneMillion`;
      sortedData.sort((a, b) => {
        if (a[typeMillion] > b[typeMillion]) {
          return -1;
        } else {
          return 1;
        }
      });
      return sortedData;
    };

    const getCountriesData = async () => {
      fetch("https://disease.sh/v3/covid-19/countries")
        .then(response => response.json())
        .then(data => {
          const countries = data.map(country => ({
            name: country.country,
            value: country.countryInfo.iso2
          }));
          let sortedData = sortData(data);
          let sortedDataMillion = sortDataMillion(data);
          setCountries(countries);
          setMapCountries(data);
          setTableData(sortedData);
          setTableDataMillion(sortedDataMillion);
        });
    };
    getCountriesData();
    // eslint-disable-next-line
  }, [casesType]);

  const onCountryChange = async e => {
    const countryCode = e.target.value;
    const url =
      countryCode === "worldwide"
        ? "https://disease.sh/v3/covid-19/all"
        : `https://disease.sh/v3/covid-19/countries/${countryCode}`;
    await fetch(url)
      .then(response => response.json())
      .then(data => {
        setInputCountry(countryCode);
        setCountryInfo(data);
      });
  };

  useEffect(() => {
    if (!countryInfo.countryInfo) {
      setMapCenter({ lat: 34.80746, lng: -40.4796 });
      setMapZoom(2);
    } else {
      setMapCenter([countryInfo.countryInfo.lat, countryInfo.countryInfo.long]);
      setMapZoom(4);
    }
  }, [countryInfo]);

  return (
    <div className={styles.container}>
      <img className={styles.image} src={coronaImage} alt="Covid-19" />

      <Card className={styles.select}>
        <CardContent>
          <FormControl className={styles.app__dropdown}>
            <Select
              variant="outlined"
              value={country}
              onChange={onCountryChange}
              className={styles.selectinput}
            >
              <MenuItem value="worldwide">Worldwide</MenuItem>
              {countries.map((country, index) => (
                <MenuItem key={index} value={country.value}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </CardContent>
        <div className={styles.outerdiv}>
          <div className={styles.innerdiv}>
            <h5 className={styles.textalign}>Population : </h5>
            <div className={styles.textdata}>
              {countryInfo.population !== undefined
                ? countryInfo.population
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                : ""}
            </div>
          </div>
          <div className={styles.innerdiv}>
            <h5 className={styles.textalign}>
              {casesType.charAt(0).toUpperCase() + casesType.slice(1)} Per 1
              Million :{" "}
            </h5>
            <div className={styles.textdata}>
              {countryInfo.population !== undefined
                ? {
                    cases: (
                      <div style={{ color: "#7f7fff", fontWeight: "600" }}>
                        {countryInfo.casesPerOneMillion}
                      </div>
                    ),
                    recovered: (
                      <div style={{ color: "#59f659", fontWeight: "600" }}>
                        {countryInfo.recoveredPerOneMillion}
                      </div>
                    ),
                    deaths: (
                      <div style={{ color: "#ff7f7f", fontWeight: "600" }}>
                        {countryInfo.deathsPerOneMillion}
                      </div>
                    )
                  }[casesType]
                : ""}
            </div>
          </div>
        </div>
      </Card>
      <Cards
        data={countryInfo}
        setCasesType={setCasesType}
        casesType={casesType}
      />
      <Map
        countries={mapCountries}
        casesType={casesType}
        center={mapCenter}
        zoom={mapZoom}
      />
      <div className={styles.graph}>
        <Card className={styles.chart1}>
          <CardContent>
            <div className="app__information">
              <h3>
                {countryInfo.country} new {casesType}
              </h3>
              <LineGraph casesType={casesType} country={country} />
            </div>
          </CardContent>
        </Card>
        <Card className={styles.chart1}>
          <CardContent className={styles.cardcontent}>
            <Chart
              data={countryInfo}
              country={country}
              countryName={countryInfo.country}
            />
          </CardContent>
        </Card>
      </div>
      <div className={styles.lists}>
        <Card className={styles.chart1}>
          <CardContent>
            <h3>
              {casesType.charAt(0).toUpperCase() + casesType.slice(1)} by
              Country
            </h3>
            <Table
              countries={tableData}
              million={false}
              casesType={casesType}
            />
          </CardContent>
        </Card>
        <Card className={styles.chart1}>
          <CardContent>
            <h3>
              {casesType.charAt(0).toUpperCase() + casesType.slice(1)} by
              Country Per 1 Million
            </h3>
            <Table
              countries={tableDataMillion}
              million={true}
              casesType={casesType}
            />
          </CardContent>
        </Card>
      </div>
      <div
        className={styles.scrollTop}
        onClick={scrollTop}
        style={{ height: 40, display: showScroll ? "flex" : "none" }}
      >
        {/* <span class="screen-reader-text">Back to Top</span> */}
        <svg width="32" height="32" viewBox="0 0 100 100">
          <path
            fill="#d6a26c"
            d="m50 0c-13.262 0-25.98 5.2695-35.355 14.645s-14.645 22.094-14.645 35.355 5.2695 25.98 14.645 35.355 22.094 14.645 35.355 14.645 25.98-5.2695 35.355-14.645 14.645-22.094 14.645-35.355-5.2695-25.98-14.645-35.355-22.094-14.645-35.355-14.645zm20.832 62.5-20.832-22.457-20.625 22.457c-1.207 0.74219-2.7656 0.57812-3.7891-0.39844-1.0273-0.98047-1.2695-2.5273-0.58594-3.7695l22.918-25c0.60156-0.61328 1.4297-0.96094 2.2891-0.96094 0.86328 0 1.6914 0.34766 2.293 0.96094l22.918 25c0.88672 1.2891 0.6875 3.0352-0.47266 4.0898-1.1562 1.0508-2.9141 1.0859-4.1133 0.078125z"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default App;
