import React, { useState, useEffect } from "react";
import { fetchDailyData } from "../../api";
import { Line, Bar } from "react-chartjs-2";
import numeral from "numeral";

import styles from "./Chart.module.css";

const Chart = ({
  data: { cases, recovered, deaths },
  country,
  countryName
}) => {
  const [dailyData, setDailyData] = useState([]);
  useEffect(() => {
    const fetchAPI = async () => {
      setDailyData(await fetchDailyData());
    };
    fetchAPI();
  }, []);

  const options = {
    legend: {
      display: true
    },
    elements: {
      point: {
        radius: 0
      }
    },
    maintainAspectRatio: false,
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        label: function (tooltipItem, data) {
          return numeral(tooltipItem.value).format("+0,0");
        }
      }
    },
    scales: {
      xAxes: [
        {
          type: "time",
          time: {
            parser: "YYYY/MM/DD",
            tooltipFormat: "ll"
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            display: false
          },
          ticks: {
            // Include a dollar sign in the ticks
            callback: function (value, index, values) {
              return numeral(value).format("0a");
            }
          }
        }
      ]
    }
  };

  const lineChart = dailyData.length ? (
    <Line
      data={{
        labels: dailyData.map(({ date }) => date),
        datasets: [
          {
            data: dailyData.map(({ confirmed }) => confirmed),
            label: "Cases",
            borderColor: "#7f7fff",
            fill: true
          },
          {
            data: dailyData.map(({ deaths }) => deaths),
            label: "Deaths",
            borderColor: "#ff7f7f",
            backgroundColor: "rgba(255, 0, 0, 0.5)",
            fill: true
          }
        ]
      }}
      options={options}
    />
  ) : null;

  const barChart = cases ? (
    <Bar
      data={{
        labels: ["Cases", "Recovered", "Deaths"],
        datasets: [
          {
            label: "People",
            backgroundColor: [
              "rgba(0, 0, 255, 0.5",
              "rgba(0, 255, 0, 0.5",
              "rgba(255, 0, 0, 0.5"
            ],
            data: [cases, recovered, deaths]
          }
        ]
      }}
      options={{
        legend: { display: false },
        title: { display: true, text: `Current state in ${countryName}` },
        scales: {
          yAxes: [
            {
              gridLines: {
                display: false
              },
              ticks: {
                // Include a dollar sign in the ticks
                callback: function (value, index, values) {
                  return numeral(value).format("0a");
                }
              }
            }
          ]
        }
      }}
    />
  ) : null;

  return (
    <div className={styles.container}>
      {country === "worldwide" ? lineChart : barChart}
    </div>
  );
};

export default Chart;
